import { CoalIndexedPriceService, ColaIndexTypeEnum, IndexedDataDto, ProrateGradeIndexes, SpecificationsEnum } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Row, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AlertMessages } from '../../../../../common';
import './coal-index-view.css';




interface IProps {
  indexedData: IndexedDataDto
  cancelHandler: () => void
}

export const CoalIndexView = (props: IProps) => {
  const { formatMessage: fm } = useIntl();
  const [data, setData] = useState([]);
  const { indexedData, cancelHandler } = props;
  const service = new CoalIndexedPriceService();
  const columns = [];
  columns.push(
    {
      title: indexedData.coalIndexType === ColaIndexTypeEnum.WEEKLY ? 'Week Ending' : 'Month Ending',
      dataIndex: indexedData.coalIndexType === ColaIndexTypeEnum.WEEKLY ? 'weekEndingDate' : 'monthYear',
    }
  );


  indexedData?.proRateIndexName?.toString()?.split(',').forEach((garIndex, index) => {
    if (garIndex.slice(0, 3) === 'gar') {
      let narIndex = 0;
      let garIndexNumber = Number(garIndex.slice(3, garIndex.length));
      // ProrateGradeIndexes.garIndexes.find((grade, index) => {
      //   if (grade === garIndexNumber) {
      //     narIndex = index;
      //   }
      // })
      columns.push({
        title: `${garIndexNumber}  ${SpecificationsEnum.GAR} (${ProrateGradeIndexes.narIndexes[narIndex]}  ${SpecificationsEnum.NAR})`,
        dataIndex: `${garIndex}`,
      });
    } else {
      let narIndex = 0;
      let garIndexNumber = Number(garIndex.slice(3, garIndex.length));
      // ProrateGradeIndexes.narIndexes.find((grade, index) => {
      //   if (grade === garIndexNumber) {
      //     narIndex = index;
      //   }
      // })
      columns.push({
        title: `${ProrateGradeIndexes.garIndexes[narIndex]}  ${SpecificationsEnum.GAR} (${garIndexNumber}  ${SpecificationsEnum.NAR})`,
        dataIndex: `gar${ProrateGradeIndexes.garIndexes[narIndex]}`,
      });
    }

  });

  useEffect(() => {
    let requestObject = {
      indexIds: indexedData.indexIds.split(','),
      coalIndexType: indexedData.coalIndexType
    }
    getIndexedData(requestObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexedData]);

  const getIndexedData = (requestObj: any) => {
    service.getIndexedDataOfGivenIds(requestObj).then(res => {
      if (res.status) {
        setData(res.data);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const getAvgData = (garIndex: string, weight: number) => {
    let total = 0;
    data.forEach(rec => {
      total += Number(rec[garIndex])
    })
    return parseFloat(((total / data.length) * (weight / 100)).toFixed(2));
  }


  return (
    <>
      <Card
        style={{ height: '90vh', width: '100%', top: 0, bottom: 0 }}
        bodyStyle={{ maxHeight: '90vh' }}
        actions={[
          <Row justify='space-around'>
            <Col></Col>
            <Col>
              <Button onClick={() => { cancelHandler(); }}>close</Button>
            </Col>
          </Row>
        ]}>

        <div className='row'>
          <label className='contract_display_header_label' style={{ color: 'grey' }}>Coal Index Type</label>
          &nbsp;&nbsp;&nbsp;<span><b>{indexedData.coalIndexType}</b></span>
        </div><br />


        <Table columns={columns} dataSource={data} pagination={false} />
        <br />

        <div className='coal-index-css'>
          <table style={{ width: '100%' }}>
            <tr>
              <td>Prorata Grade Considered</td><td>{indexedData.proRateIndexName?.toString()}</td>
            </tr>
            {
              indexedData.isWeighted && <>
                <tr>
                  <td>Base line Gar</td><td>{indexedData.baselineGar}</td>
                </tr>
                {indexedData.proRateIndexName?.toString()?.split(',').map((rec, index) => {
                  const weight = Number(indexedData.prorataGarWeights.split(',')[index]);
                  return <tr>
                    <td>{weight}%</td><td>{getAvgData(rec, weight)}</td>
                  </tr>
                })}

              </>
            }
            <tr><td>Aveage coal Base Price PMT</td><td>{indexedData.avgCoalBasePrice}</td></tr>
            <tr><td>Prorata Coal Base Price PMT</td><td>{indexedData.proRateCoalBasePrice}</td></tr>
            <tr><td>Bonus Base Price PMT</td><td>{indexedData.bonusPricePMT} USD</td></tr>
            <tr><td>Discount Price PMT</td><td>{indexedData.discountPricePMT} USD</td></tr>
            <tr><td>Derived Coal Base Price PMT</td><td>{indexedData.finalCoalBasePricePMT} USD</td></tr>
            <tr><td><b>Final Coal Base Price PMT</b></td><td><b>{indexedData.finalCoalBasePricePMT} USD</b></td></tr>
          </table>
        </div>
      </Card>
    </>
  )
}

export default CoalIndexView;

