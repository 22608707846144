import { DownOutlined } from '@ant-design/icons';
import { AdvanceEnum, AdvanceReceiversService, BusinessPartnerBpIdDto, BusinessPartnerService, ContractIdRequest, ContractStatusEnum, CurrencyConverterService, CurrencyExchangeRequestDto, CurrencyService, PurchaseContractService, VendorAdvService } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Collapse, Dropdown, Form, MenuProps, Row, Space } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, PreviewComponent, useAuthState } from '../../../../common';
import AdvanceReceivablesForm from './advance-receivables-form';

const AdvanceReceivablesPreview = () => {
  const { formatMessage: fm } = useIntl();
  const { Panel } = Collapse;

  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { authContext } = useAuthState();

  const [defaultOpenKey, setDefaultOpenKey] = useState(['1']);
  const [receivablesData, setReceivablesData] = useState<any>();
  const [formRef] = Form.useForm();
  const [bpName, setBpName] = useState<any>()
  const [contractNo, setContractNo] = useState<any>()
  const [currencyName, setCurrencyName] = useState<any>()
  const [negotiationCurrency, setNegotiationCurrency] = useState<any>()
  const [goToButtonKeys, setGoToButtonKeys] = useState([]);

  const businessPartnerService = new BusinessPartnerService();
  const PurchaseContract = new PurchaseContractService();
  const currencyService = new CurrencyService();
  const currencyConverterService = new CurrencyConverterService();
  const service = new AdvanceReceiversService();
  let pathToreDirect = '/advance-receivables';



  const { record } = state ? state : { record: null }
  const COLUMN_STYLE = { width: 2000 };

  useEffect(() => {
    let limit = Number(defaultOpenKey[0]);
    let i: number, goto: number[] = [];
    for (i = 1; i < limit; i++) {
      goto.push(i);
    }
    setGoToButtonKeys(goto);
  }, [defaultOpenKey])




  useEffect(() => {
    if (state) {
      setDefaultData();
    }
  }, [state]);

  useEffect(() => {
    let limit = Number(defaultOpenKey[0]);
    let i: number, goto: number[] = [];
    for (i = 1; i < limit; i++) {
      goto.push(i);
    }
    setGoToButtonKeys(goto);
  }, [defaultOpenKey])

  const setDefaultData = () => {
    if(state) {
      const newState = { ...state };
      newState.agreementDate = moment(state.contractDate);
      if (newState.jisdorDate && newState.negotiationCurrency && newState.billingCurrency) {
        newState.jisdorDate = moment(state.jisdorDate);
        const req = new CurrencyExchangeRequestDto(newState.negotiationCurrency, newState.billingCurrency, moment(newState.jisdorDate).format('YYYY-MM-DD'))
        currencyConverterService.getCurrencyExchangeRate(req).then(res => {
          if (res.status) {
            newState['displayExchangeRate'] = res?.data?.displayExchangeRate;
          } else {
            newState['displayExchangeRate'] = res?.data?.displayExchangeRate;
          }
          setReceivablesData(newState);
        }).catch(err => {
          console.log('error:' + err.message);
          setReceivablesData(newState);
        })
      } else setReceivablesData(newState);

    }
  }

  const VendorAdvanceFormhandler = () => {
    //TODO:create perfect DTO
    formRef.validateFields().then(values => {

      setReceivablesData((prevState) => { return { ...prevState, ...values, versionFlag: receivablesData?.versionFlag, paymentTerms: { paymentMode: values?.paymentMode, lc: values?.lc, lcType: values?.lcType, lcValue: values?.lcValue, others: values?.others, telegraphicTransfer: values?.telegraphicTransfer, actLedger: values.actLedger } } });
      setDefaultOpenKey(['2']);

    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }


  useEffect(() => {
    if (formRef.getFieldValue('vendorId')) {
      getBpName()
    }
  }, [formRef.getFieldValue('vendorId')])

  const getBpName = () => {
    const req = new BusinessPartnerBpIdDto(formRef.getFieldValue('vendorId'));
    businessPartnerService.getBpName(req).then(res => {
      if (res.bpName) {
        setBpName(res.bpName);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };




  useEffect(() => {
    if (formRef.getFieldValue("billingCurrency")) {
      getCurrencyName()
    }
  }, [formRef.getFieldValue("billingCurrency")])

  const getCurrencyName = () => {
    currencyService.getCurrncyName({ currencyId: formRef.getFieldValue("billingCurrency") }).then(res => {
      setCurrencyName(res.currencyCode);
    }).catch(err => {
      console.log('error:' + err.message);
    })
  };

  useEffect(() => {
    if (formRef.getFieldValue("negotiationCurrency")) {
      getNegotiationCurrency()
    }
  }, [formRef.getFieldValue("negotiationCurrency")])


  const getNegotiationCurrency = () => {
    currencyService.getCurrncyName({ currencyId: formRef.getFieldValue("negotiationCurrency") }).then(res => {
      setNegotiationCurrency(res.currencyName);
    }).catch(err => {
      console.log('error:' + err.message);
    })

  };


  const columns: ColumnsType<any> = [
    {
      title: 'salesContract',
      dataIndex: 'contractNo',
      width: '30%',
      align: 'center',

    },

    {
      title: 'Vendor',
      dataIndex: 'bpName',
      width: '40%',
      align: 'center',

    },
    {
      title: 'Billing Currency',
      dataIndex: 'currencyName',
      width: '30%',
      align: 'center',

    },
    // {
    //   title: 'Negotiation Currency',
    //   dataIndex: 'systemCurrency',
    //   width: '30%',
    //   align: 'center',

    // },


  ]

  const getTableData = () => {
    const tableDAta = [{ currencyName: currencyName, contractNo: receivablesData?.salesContractNo, bpName: bpName, }]
    return tableDAta
  }

  const previewDataLabels = [{
    title: fm({ id: `step1heading`, defaultMessage: `${state?.contract} Details` }),
    keysAndLabelMapping: [
      {
        key: "VendorAdvance",
        labels: {
          contractDate: fm({ id: "mdm.components.vendorAdvance.contractDate", defaultMessage: 'Contract Date' }),
          advanceAmount: fm({ id: "mdm.components.vendorAdvance.advanceAmount", defaultMessage: 'Advance Amount' }),
          displayExchangeRate: fm({ id: "mdm.components.vendorAdvance.exchangeRate", defaultMessage: 'Exchange Rate' }),
          jisdorDate: fm({ id: "mdm.components.vendorAdvance.jisdorDate", defaultMessage: 'JISDOR Date' }),
          amount: fm({ id: "mdm.components.vendorAdvance.amount", defaultMessage: 'Amount' }),
          deductionType: fm({ id: 'mdm.components.vendorAdvance.deductionType', defaultMessage: "Deduction Type" }),
          pricePMT: fm({ id: 'mdm.components.vendorAdvance.pricePMT', defaultMessage: "PricePMT" }),
          lumpSum: fm({ id: 'mdm.components.vendorAdvance.lumpSum', defaultMessage: "LumpSum" }),
          deductionCategory: fm({ id: 'mdm.components.vendorAdvance.deductionCategory', defaultMessage: "DeductionCategory" }),
          remarks: fm({ id: 'mdm.components.vendorAdvance.remarks', defaultMessage: "Remarks" }),
        }
      }
    ],
    isGridDisplay: false
  }]

  const clearVendorAdvance = () => {
    formRef.resetFields()
  }

  const saveDownPayments = () => {
    if(state && state?.status === ContractStatusEnum.ACTIVE){
        service.createAdvReceivablesAddendum({ ...receivablesData, plantCode: authContext.defaultPlant, userName: authContext.user.userName, contractType: AdvanceEnum.AdvanceReceivables }).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setTimeout(() => {
              navigate(pathToreDirect);
            }, 1000);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
    } else {
        service.createAdvReceivables({ ...receivablesData, plantCode: authContext.defaultPlant, userName: authContext.user.userName, contractType: AdvanceEnum.AdvanceReceivables }).then(res => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setTimeout(() => {
              navigate(pathToreDirect);
            }, 1000);
        } else {
            AlertMessages.getErrorMessage(res.internalMessage);
        }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }
  }




  const goToOnClickHandler: MenuProps['onClick'] = ({ key }) => {
    setDefaultOpenKey([`${key}`]);
  };


  const items = goToButtonKeys.map(item => {
    return {
      label: `${item}`,
      key: `${item}`,
    }
  })
  return (
    <div>
      <Collapse activeKey={defaultOpenKey} >
        <Panel header="Advance" key='1' >
          <AdvanceReceivablesForm formRef={formRef} initialValues={receivablesData} layoutType='vertical'>
            <Row justify="end">
              <Col>
                {
                  <Space>

                    <Button
                      onClick={() => { clearVendorAdvance(); }}
                    >
                      Clear
                    </Button>
                    <Button onClick={VendorAdvanceFormhandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }
              </Col>
            </Row>
          </AdvanceReceivablesForm>

        </Panel>
        <Panel header="Preview" key="2">
          <>
            <PreviewComponent data={{ VendorAdvance: receivablesData }} labels={previewDataLabels} primaryKeyData={{}}>
              <Row justify="end">
                <Col>
                  <div >
                    <Card bodyStyle={{ overflow: 'auto' }}>
                      <Table columns={columns} dataSource={getTableData()} pagination={false} bordered={true} style={COLUMN_STYLE} />
                    </Card>
                  </div>
                  <br />
                  {
                    <Row justify="end">
                      <Space >
                        <Dropdown.Button
                          icon={<DownOutlined />}
                          menu={{ items, onClick: goToOnClickHandler }}
                        >
                          {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                        </Dropdown.Button>
                        <Button onClick={saveDownPayments} type="primary">
                          Save
                        </Button>
                      </Space>
                    </Row>
                  }</Col>
              </Row>
            </PreviewComponent>
          </>
        </Panel>
      </Collapse>
    </div>
  )
}

export default AdvanceReceivablesPreview





