import { BusinessPartnerBpIdDto, BusinessPartnerService, ContractStatusEnum, CurrencyService, PaymentModesEnum, VesselAgreementService, } from '@exportx/shared-models-and-services';
import { getFilesData } from '@exportx/ui-utils';
import { Button, Card, Col, Collapse, Dropdown, Form, Input, Menu, MenuProps, Row, Space } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, FileUploadForm, PreviewComponent, useAuthState } from '../../../../common';
import { DownOutlined } from '@ant-design/icons';
import NewVesselAgreement from './vessel-agreement-form'
import { SaveAndNextButton } from '../../../contracts/creation-forms';
import PaymentTerm from '../../../contracts/creation-forms/payment-terms';


const { Panel } = Collapse;
const { TextArea } = Input;

const VesselAgreementPreview = () => {
  const navigate = useNavigate();
  //Common for all Page Components
  const { formatMessage: fm } = useIntl();

  const [defaultOpenKey, setDefaultOpenKey] = useState(['1']);

  const [agreementData, setAgreementData] = useState<any>({
    specPriceData: [''],
    filesData: [],
    paymentTerms: { paymentMode: PaymentModesEnum.TELEGRAPHIC_TRANSFER, paymentTerms: ['', '', '', ''] },
    remarks: '',
  });

  const businessPartnerService = new BusinessPartnerService();
  const currencyService = new CurrencyService();
  const [bpName, setBpName] = useState<any>();
  const [currencyName, setCurrencyName] = useState<any>();
  const service = new VesselAgreementService();
  const { authContext } = useAuthState();
  const [goToButtonKeys, setGoToButtonKeys] = useState([]);

  const { state }: any = useLocation();

  let pathToreDirect = '/vesselAgreement-grid';


  const [formRef] = Form.useForm();
  const [PaymentTermFormRef] = Form.useForm();
  const [filesFormRef] = Form.useForm();
  const [remarksFormRef] = Form.useForm();

  const { record } = state ? state : { record: null };
  const COLUMN_STYLE = { width: 2000 };

  useEffect(() => {
    if (record) {
      const recordData = { ...record };
      recordData?.specPriceData.forEach((item, index) => {
        if (Array.isArray(item?.layCan)) {
          let layCan = item?.layCan;
          recordData.specPriceData[index].layCan = [moment(layCan[0]), moment(layCan[1])];
        }
      });
      recordData.agreementDate = moment(record.agreementDate);
      formRef.resetFields();
      setAgreementData(recordData);
    }

  }, [record])

  const agreementFormHandler = () => {
    formRef.validateFields().then(values => {
      console.log('agreementFormHandler =>', values)
      setAgreementData((prevState) => { return { ...prevState, ...values } });
      setDefaultOpenKey(['2']);
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const paymentTermFormHandler = () => {
    PaymentTermFormRef.validateFields().then(values => {
      setAgreementData((prevState) => { return { ...prevState, paymentTerms: values } });
      setDefaultOpenKey(['3']);
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }


  useEffect(() => {
    if (formRef.getFieldValue('vesselVendorId')) {
      BpName()
    }
  }, [formRef.getFieldValue('vesselVendorId')])

  const BpName = () => {
    const req = new BusinessPartnerBpIdDto(formRef.getFieldValue('vesselVendorId'));
    businessPartnerService.getBpName(req).then(res => {
      if (res.bpName) {
        setBpName(res.bpName)
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  useEffect(() => {
    const currencyId = formRef.getFieldValue('currency');
    if (currencyId) {
      getCurrencyName(currencyId)
    }
  }, [formRef.getFieldValue('currency')])

  const getCurrencyName = (currencyId) => {
    currencyService.getCurrncyName({ currencyId }).then(res => {
      setCurrencyName(res.currencyCode);
    }).catch(err => {
      console.log('error:' + err.message);
    })
  };

  const Columns: ColumnsType<any> = [
    {
      title: "Vessel Vendor",
      dataIndex: 'bpName',
      width: '40%',
      align: 'center',

    },
    {
      title: 'Currency',
      dataIndex: 'currencyName',
      width: '30%',
      align: 'center',

    }
  ]

  const getTableData = () => {
    const tableDAta = [{ currencyName: currencyName, bpName: bpName }]
    return tableDAta
  }


  useEffect(() => {
    let limit = Number(defaultOpenKey[0]);
    let i: number, goto: number[] = [];
    for (i = 1; i < limit; i++) {
      goto.push(i);
    }
    setGoToButtonKeys(goto);
  }, [defaultOpenKey])


  const goToOnClickHandler: MenuProps['onClick'] = ({ key }) => {
    setDefaultOpenKey([`${key}`]);
  };


  const previewDataLabels = [{
    title: fm({ id: `step1heading`, defaultMessage: `Vessel Agreement Details` }),
    keysAndLabelMapping: [
      {
        key: "newBargeAgreement",
        labels: {
          agreementDate: fm({ id: 'mdm.components.vesselAgreementForm.agreementDate', defaultMessage: "Agreement Date" }),
          specPriceData: fm({ id: 'mdm.components.vesselAgreementForm. specPriceData', defaultMessage: 'Spec Price Data' }),
          remarks: fm({ id: 'mdm.components.vesselAgreementForm.remarks', defaultMessage: 'Remarks' }),
        }, innerGridKeys: ['specPriceData'],
        innerGridLabels: {
          laycan: fm({ id: 'mdm.components.vesselAgreementForm.laycan', defaultMessage: 'Laycan' }),
          vesselName: fm({ id: 'mdm.components.vesselAgreementForm.vesselName', defaultMessage: 'Vessel Name' }),
          quantity: fm({ id: 'mdm.components.vesselAgreementForm.quantity', defaultMessage: 'Quantity' }),
          tolerance: fm({ id: 'mdm.components.vesselAgreementForm.tolerance', defaultMessage: 'Tolerance' }),
          priceType: fm({ id: 'mdm.components.vesselAgreementForm.priceType', defaultMessage: 'Price Type' }),
          freightPrice: fm({ id: 'mdm.components.vesselAgreementForm.freightPrice', defaultMessage: "Freight Price" }),
          vatInclOrExcl: fm({ id: 'mdm.components.vesselAgreementForm.vatInclOrExcl', defaultMessage: 'VAT Applicability (PPN)' }),
          loadPortName: fm({ id: 'mdm.components.vesselAgreementForm.loadPort', defaultMessage: 'Load Port' }),
          loadRate: fm({ id: 'mdm.components.vesselAgreementForm.loadRate', defaultMessage: 'Load Rate' }),
          dischargePortName: fm({ id: 'mdm.components.vesselAgreementForm.dischargePort', defaultMessage: 'Discharge Port' }),
          dischargeRate: fm({ id: 'mdm.components.vesselAgreementForm.dischargeRate', defaultMessage: 'Discharge Rate' }),
          demurrageRate: fm({ id: 'mdm.components.vesselAgreementForm.demurrageRate', defaultMessage: 'Demurrage Rate' }),
          dispatchRate: fm({ id: 'mdm.components.vesselAgreementForm.dispatchRate', defaultMessage: 'Dispatch Rate' }),

        },
        innerGridKey: ['filesData'],
        innerGridLabel: {
          fileDescription: fm({ id: "common.fileUploadForm.fileDescription", defaultMessage: 'File Description' }),
        },
      },

    ],
    isGridDisplay: false
  }]

  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => {
      getFilesData(fileValues.filesData).then(filesData => {
        setAgreementData((prevState) => {
          const requestObject = { ...prevState };
          return { ...requestObject, filesData: [...filesData] }
        });
        setDefaultOpenKey(['4']);
      });
    });
  }

  const submitHandler = () => {

    const payload = { ...agreementData };
    const priceData = [];
    payload?.specPriceData?.forEach(element => {
      priceData.push({
        vgApId: element?.vgApId,
        vesselName: element?.vesselName,
        quantity: element?.quantity,
        tolerance: element?.tolerance,
        layCan: element?.layCan,
        price: element?.price,
        priceAfterVat: element?.priceAfterVat,
        currency: payload?.currency,
        priceType: element?.priceType,
        loadPort: element?.loadPort,
        loadRate: element?.loadRate,
        dischargePort: element?.dischargePort,
        dischargeRate: element?.dischargeRate,
        demurrageRate: element?.demurrageRate,
        dispatchRate: element?.dispatchRate,
        vatInclOrExcl: element?.vatInclOrExcl,
        vatId: element?.vatId,
        freightPrice: element?.freightPrice
      })

    });
    payload.specPriceData = priceData



    if (agreementData && agreementData?.status === ContractStatusEnum.ACTIVE) {
      service.createVesselAgreementAddendum({ ...payload, plantCode: authContext.defaultPlant, createdUser: authContext.user.userName }).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `BargeAgreement created SuccessFully` }));
          setTimeout(() => {
            navigate(pathToreDirect);
          }, 1000);
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    } else {
      console.log('form data =>', agreementData)
      console.log('payload =>', { ...payload, plantCode: authContext.defaultPlant, createdUser: authContext.user.userName })
      service.create({ ...payload, plantCode: authContext.defaultPlant, createdUser: authContext.user.userName }).then(res => {
        console.log('res')
        if (res.status) {
          AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Vessel Agreement created SuccessFully` }));
          setTimeout(() => {
            navigate(pathToreDirect);
          }, 1000);
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }
  }

  const items = goToButtonKeys.map(item => {
    return {
      label: `${item}`,
      key: `${item}`,
    }
  });

  return (
    <div>
      <Collapse activeKey={defaultOpenKey} >
        <Panel header="Vessel Agreement" key='1' >
          <NewVesselAgreement
            formRef={formRef}
            initialValues={agreementData}
            layoutType='vertical'
          >
            <Row justify="end">
              <Col>
                <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={agreementFormHandler} />

              </Col>
            </Row>
          </NewVesselAgreement>

        </Panel>
        <Panel header="Payment Term" key="2">
          <PaymentTerm formRef={PaymentTermFormRef} initialValues={agreementData.paymentTerms} layoutType='vertical'>
            <Row justify="end">
              <Col>
                <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={paymentTermFormHandler} />

              </Col>
            </Row>
          </PaymentTerm>
        </Panel>

        <Panel header="Remarks & Upload Documents" key="3">
          <FileUploadForm maxCount={3} layoutType='vertical' formRef={filesFormRef}
            initialValues={agreementData.action !== 'addendum' ? agreementData?.filesData : []}
          />
          <Row>
            <Col span={24}>
              <Form layout='vertical' form={remarksFormRef} initialValues={{ remarks: agreementData.remarks }} >
                <Form.Item label="Remarks" name="remarks">
                  <TextArea style={{ width: '100%' }} rows={5} onChange={(e) => setAgreementData(prev => ({ ...prev, remarks: e.target.value }))} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={fileUploadFormHandler} />
            </Col>
          </Row>
        </Panel>


        <Panel header="Preview" key="4">
          <>
            <PreviewComponent data={{ newBargeAgreement: agreementData }} labels={previewDataLabels} primaryKeyData={{}}>
              <Row justify="end">
                <Col>
                  <div >
                    <Card bodyStyle={{ overflow: 'auto' }}>
                      <Table columns={Columns} dataSource={getTableData()} pagination={false} bordered={true} style={COLUMN_STYLE} />
                    </Card>
                  </div>
                  <br />
                  {
                    <Row justify="end">
                      <Space >
                        <Dropdown.Button
                          icon={<DownOutlined />}
                          menu={{ items, onClick: goToOnClickHandler }}
                        >
                          {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                        </Dropdown.Button>
                        <Button onClick={submitHandler} type="primary">
                          Save
                        </Button>
                      </Space>
                    </Row>
                  }</Col>
              </Row>
            </PreviewComponent>
          </>
        </Panel>
      </Collapse>


    </div>
  )
}

export default VesselAgreementPreview;