import { BargeAgreementService, BusinessPartnerBpIdDto, BusinessPartnerService, ContractStatusEnum, CurrencyService, } from '@exportx/shared-models-and-services';
import { getFilesData } from '@exportx/ui-utils';
import { Button, Card, Col, Collapse, Dropdown, Form, Menu, MenuProps, Row, Space } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, PreviewComponent, useAuthState } from '../../../../common';
import NewBargeAgreement from './barge-agreement-form';
import { DownOutlined } from '@ant-design/icons';




const { Panel } = Collapse;
const BargeAgreementPreview = () => {
  const navigate = useNavigate();
  //Common for all Page Components
  const { formatMessage: fm } = useIntl();


  const [defaultOpenKey, setDefaultOpenKey] = useState(['1']);
  const [bargeAgreementOrderData, setBargeAgreementOrderData] = useState<any>({ specPriceData: [''], filesData: [] });
  const [bargeAgreementFormRef] = Form.useForm();

  const businessPartnerService = new BusinessPartnerService();
  const currencyService = new CurrencyService();
  const [bpName, setBpName] = useState<any>();
  const [currencyName, setCurrencyName] = useState<any>();
  const service = new BargeAgreementService();
  const { authContext } = useAuthState();
  const [goToButtonKeys, setGoToButtonKeys] = useState([]);

  const { state }: any = useLocation();

  let pathToreDirect = '/bargeAgreement-grid';


  const { record } = state ? state : { record: null };
  const COLUMN_STYLE = { width: 2000 };

  useEffect(() => {
    if (record) {
      const recordData = { ...record };
      console.log(recordData, "=======")
      recordData.agreementDate = moment(record.agreementDate);
      bargeAgreementFormRef.resetFields();
      setBargeAgreementOrderData(recordData);
    }

  }, [record])




  const bargeAgreementFormHandler = () => {
    bargeAgreementFormRef.validateFields().then(values => {
      getFilesData(values.filesData).then(filesData => {
        values.filesData = filesData;
        setBargeAgreementOrderData((prevState) => { return { ...prevState, ...values } });
        setDefaultOpenKey(['2']);
      });
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }


  useEffect(() => {
    if (bargeAgreementFormRef.getFieldValue('bargeVendorId')) {
      BpName()
    }
  }, [bargeAgreementFormRef.getFieldValue('bargeVendorId')])

  const BpName = () => {
    const req = new BusinessPartnerBpIdDto(bargeAgreementFormRef.getFieldValue('bargeVendorId'));
    businessPartnerService.getBpName(req).then(res => {
      if (res.bpName) {
        setBpName(res.bpName)
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  useEffect(() => {
    const currencyId = bargeAgreementFormRef.getFieldValue('currency');
    if (currencyId) {
      getCurrencyName(currencyId)
    }
  }, [bargeAgreementFormRef.getFieldValue('currency')])

  const getCurrencyName = (currencyId) => {
    currencyService.getCurrncyName({ currencyId }).then(res => {
      setCurrencyName(res.currencyCode);
    }).catch(err => {
      console.log('error:' + err.message);
    })
  };

  const Columns: ColumnsType<any> = [
    {
      title: "Barge Vendor",
      dataIndex: 'bpName',
      width: '40%',
      align: 'center',

    },
    {
      title: 'Currency',
      dataIndex: 'currencyName',
      width: '30%',
      align: 'center',

    }
  ]

  const getTableData = () => {
    const tableDAta = [{ currencyName: currencyName, bpName: bpName }]
    return tableDAta
  }


  useEffect(() => {
    let limit = Number(defaultOpenKey[0]);
    let i: number, goto: number[] = [];
    for (i = 1; i < limit; i++) {
      goto.push(i);
    }
    setGoToButtonKeys(goto);
  }, [defaultOpenKey])


  const goToOnClickHandler: MenuProps['onClick'] = ({ key }) => {
    setDefaultOpenKey([`${key}`]);
  };




  const previewDataLabels = [{
    title: fm({ id: `step1heading`, defaultMessage: `Barge Agreement Details` }),
    keysAndLabelMapping: [
      {
        key: "newBargeAgreement",
        labels: {
          agreementDate: fm({ id: 'mdm.components.bargeAgreementForm.agreementDate', defaultMessage: "Agreement Date" }),
          specPriceData: fm({ id: 'mdm.components.bargeAgreementForm. specPriceData', defaultMessage: 'Spec Price Data' }),
          remarks: fm({ id: 'mdm.components.bargeAgreementForm.remarks', defaultMessage: 'Remarks' }),
        }, innerGridKeys: ['specPriceData'],
        innerGridLabels: {
          price: fm({ id: 'mdm.components.bargeAgreementForm.price', defaultMessage: 'Price' }),
          area: fm({ id: 'mdm.components.bargeAgreementForm.area', defaultMessage: " Area" }),
          bargingType: fm({ id: 'mdm.components.bargeAgreementForm.bargingType', defaultMessage: 'Barging Type' }),
          vatInclOrExcl: fm({ id: 'mdm.components.bargeAgreementForm. vatInclOrExcl', defaultMessage: 'VAT Applicability (PPN)' }),

        },
        innerGridKey: ['filesData'],
        innerGridLabel: {
          fileDescription: fm({ id: "common.fileUploadForm.fileDescription", defaultMessage: 'File Description' }),
        },
      },

    ],
    isGridDisplay: false
  }]

  const clearBargeAgreement = () => {
    bargeAgreementFormRef.resetFields()

  }
  const upDateBargeAgreement = () => {
    if(record && record?.status === ContractStatusEnum.ACTIVE){
      service.createBargeAgreementAddendum({ ...bargeAgreementOrderData, plantCode: authContext.defaultPlant, createdUser: authContext.user.userName }).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `BargeAgreement created SuccessFully` }));
          setTimeout(() => {
            navigate(pathToreDirect);
          }, 1000);
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    } else {
      service.create({ ...bargeAgreementOrderData, plantCode: authContext.defaultPlant, createdUser: authContext.user.userName }).then(res => {
        if (res.status) {
          AlertMessages.getSuccessMessage(`Barge Agreement updated SuccessFully`);
          setTimeout(() => {
            navigate(pathToreDirect, { state: { record: record } });

          }, 1000);
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
  }
  }

  const items = goToButtonKeys.map(item => {
    return {
      label: `${item}`,
      key: `${item}`,
    }
  });

  return (
    <div>
      <Collapse activeKey={defaultOpenKey} >
        <Panel header="Barge Agreement" key='1' >
          <NewBargeAgreement formRef={bargeAgreementFormRef} initialValues={bargeAgreementOrderData
          } layoutType='vertical'>
            <Row justify="end">
              <Col>
                {
                  <Space>

                    <Button
                      onClick={() => { clearBargeAgreement(); }}
                    >
                      Clear
                    </Button>
                    <Button onClick={bargeAgreementFormHandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </NewBargeAgreement>

        </Panel>


        <Panel header="Preview" key="2">
          <>
            <PreviewComponent data={{ newBargeAgreement: bargeAgreementOrderData }} labels={previewDataLabels} primaryKeyData={{}}>
              <Row justify="end">
                <Col>
                  <div >
                    <Card bodyStyle={{ overflow: 'auto' }}>
                      <Table columns={Columns} dataSource={getTableData()} pagination={false} bordered={true} style={COLUMN_STYLE} />
                    </Card>
                  </div>
                  <br />
                  {
                    <Row justify="end">
                      <Space >
                        <Dropdown.Button
                          icon={<DownOutlined />}
                          menu={{ items, onClick: goToOnClickHandler }}
                        >
                          {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                        </Dropdown.Button>
                        <Button onClick={upDateBargeAgreement} type="primary">
                          Save
                        </Button>
                      </Space>
                    </Row>
                  }</Col>
              </Row>
            </PreviewComponent>
          </>
        </Panel>
      </Collapse>


    </div>
  )
}

export default BargeAgreementPreview;