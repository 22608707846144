import { CostingWorkLogsEnum, ContractModesEnum, PerformaCoalCostingService, CommercialCoalCostingService, CalculationFilterDataReq, ContractStatusEnum, ReferenceFeatures, BargeCostingsEnum } from "@exportx/shared-models-and-services";
import { Row, } from 'antd';
import { PerformaApprovalDetails, BargeCostingDetails, CmApprovalDetails, ExpenseEntryCostingDetails, DeadFreightApprovalDetails, DemurrageDispatchDetails } from ".";

interface IBargeCostingChild {
  costingId: string;
  costingType: CostingWorkLogsEnum | ReferenceFeatures | BargeCostingsEnum;
  remainingAmount?: number
}


export const AccountPayableCostings = (props: IBargeCostingChild) => {
  const { costingType, costingId, remainingAmount } = props;

  let componentToRender;
  switch (costingType) {
    case CostingWorkLogsEnum.PERFORMA_PC_COSTING:
      componentToRender = <PerformaApprovalDetails costingId={costingId} contractType={ContractModesEnum.PURCHASE} remainingAmount={remainingAmount} />;
      break;
    case CostingWorkLogsEnum.PERFORMA_SC_COSTING:
      componentToRender = <PerformaApprovalDetails costingId={costingId} contractType={ContractModesEnum.SALES} remainingAmount={remainingAmount} />;
      break;
    case CostingWorkLogsEnum.BARGE_COSTING:
      componentToRender = <BargeCostingDetails costingId={costingId} remainingAmount={remainingAmount} />;
      break;
    case ReferenceFeatures.BARGE_COSTINGS:
      componentToRender = <BargeCostingDetails costingId={costingId} remainingAmount={remainingAmount} />;
      break;
    case CostingWorkLogsEnum.PROCUREMENT_FEES:
      componentToRender = <CmApprovalDetails costingId={costingId} contractType={ContractModesEnum.PROCUREMENT_FEES} remainingAmount={remainingAmount} />;
      break;
    case CostingWorkLogsEnum.MARKETING_FEES:
      componentToRender = <CmApprovalDetails costingId={costingId} contractType={ContractModesEnum.MARKETING_FEES} remainingAmount={remainingAmount} />;
      break;
    case CostingWorkLogsEnum.COMMERCIAL_PC_COSTING:
      componentToRender = <CmApprovalDetails costingId={costingId} contractType={ContractModesEnum.PURCHASE} remainingAmount={remainingAmount} />;
      break;
    case CostingWorkLogsEnum.COMMERCIAL_SC_COSTING:
      componentToRender = <CmApprovalDetails costingId={costingId} contractType={ContractModesEnum.SALES} remainingAmount={remainingAmount} />;
      break;
    case ReferenceFeatures.EXPENSE_ENTRY || CostingWorkLogsEnum.EXPENSE_ENTRY:
      componentToRender = <ExpenseEntryCostingDetails expenseEntryId={costingId} remainingAmount={remainingAmount} />;
      break;
    case BargeCostingsEnum.DEAD_FREIGHT :
      componentToRender = <DeadFreightApprovalDetails costingId={costingId} remainingAmount={remainingAmount} />;
      break;
    case BargeCostingsEnum.DEMURRAGE :
      componentToRender = <DemurrageDispatchDetails costingId={costingId} remainingAmount={remainingAmount} />;
      break;

    default: componentToRender = <b>{costingType}</b>
  }


  return (
    <>
      <Row gutter={20}>
        {componentToRender}
      </Row>
    </>

  )

}