import { CPSPreviewDto, ContractStatusEnum, ContractTypesEnum, GetAllProcurementResponseDto, PriceTypeEnum, ProcurementContractService, ProcurementContractTypeEnum } from '@exportx/shared-models-and-services';
import { getFilesData } from '@exportx/ui-utils';
import { Affix, Button, Card, Col, Descriptions, Form, Layout, Row, Space, Tooltip } from 'antd';
import Link from 'antd/es/typography/Link';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, FileUploadForm, isPermissionExist, StatusTag, useAuthState } from '../../../common';
import PaymentTermsView from '../view-components/payment-terms-view';

interface IProps {
    contractTypeCategory?: ProcurementContractTypeEnum;
}


export const ProcurementContractDetailedView = (props: IProps) => {
    const { contractTypeCategory } = props
    const [gridData, setGridData] = useState<GetAllProcurementResponseDto>();
    const [snapData, setSnapData] = useState<GetAllProcurementResponseDto[]>([]);
    const [qualities, setQualities] = useState([]);

    const [filesFormRef] = Form.useForm();
    const { authContext } = useAuthState();
    const location = useLocation();
    const paramCpcId = queryString.parse(location.search)?.cpcId;
    let { cpcId }: any = paramCpcId != null ? { cpcId: paramCpcId } : { cpcId: null };

    const laycanDateFormate = (value: any) => value && Array.isArray(value) ? `${moment(value[0]).format('DD MMM YYYY')} - ${moment(value[1]).format('DD MMM YYYY')}` : `${moment(value?.split(',')[0]).format('DD MMM YYYY')} - ${moment(value?.split(',')[1]).format('DD MMM YYYY')}` || ''


    const snapQuality = snapData?.[0]?.quality ?? [];
    const qualityData = gridData?.quality ?? [];

    useEffect(() => {

        const snap_qualities = JSON.parse(JSON.stringify(snapQuality));
        const addendum_qualites = JSON.parse(JSON.stringify(qualityData));
        const new_qualities = []
        if (gridData?.contractTypes === ContractTypesEnum.ADDENDUM) {

            const removed_records = snap_qualities?.filter(({ qualitySeq: id1 }) => !addendum_qualites.some(({ qualitySeq: id2 }) => id2 === id1));
            console.log('removed_records', removed_records)
            for (const obj of removed_records) {
                obj.color = "#f65c8d1f";
                new_qualities.push(obj);
            }
        }
        setQualities([...new_qualities, ...addendum_qualites])

    }, [snapQuality, qualityData])


    const navigate = useNavigate()
    let pathToreDirect = `/${contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees ? "s" : "p"}o-detail-view`;

    let pcUpdatePath = '';

    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
        pcUpdatePath = '/procurement-service-update'
    } if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
        pcUpdatePath = '/coalServiceFee-service-update'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
        pcUpdatePath = '/marketing-service-update'
    }


    let cpsAddendum = '';
    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
        cpsAddendum = '/procurement-addendum'
    } if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
        cpsAddendum = '/coalServiceFee-addendum'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
        cpsAddendum = '/marketing-addendum'
    }

    let detailViewPath = '';
    if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
        detailViewPath = '/procurement-contract-detailedView'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Coal_Service_Fees) {
        detailViewPath = '/coalServiceFee-detailedView'
    }
    if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
        detailViewPath = '/marketing-detailedView'
    }

    const service = new ProcurementContractService();


    useEffect(() => {
        if (!isPermissionExist([147, 156, 165])) return navigate('/')
    }, [])

    useEffect(() => {
        getServiceContractPreviewData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cpcId]);

    const getServiceContractPreviewData = () => {
        const req = new CPSPreviewDto(cpcId, authContext.defaultPlant, contractTypeCategory, undefined, authContext.user.userName);
        service.getServiceContractPreviewData(req).then(res => {
            if (res.status) {
                setGridData(res.data);
                setSnapData(res.snapData);
            } else {
                AlertMessages.getErrorMessage(res.internalMessage);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    };

    const sendForApproval = () => {
        const req = new CPSPreviewDto(cpcId, authContext.defaultPlant, undefined, undefined, authContext.user.userName);
        service.sendForApproval(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getServiceContractPreviewData();
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const addendumClickHandler = () => {
        const link = `${cpsAddendum}?cpcId=${cpcId}`
        navigate(link);
    }

    const approveOrRejectServiceContract = (contractId: string, status: ContractStatusEnum, contractTypes: any) => {
        if (!contractTypes) {
            return
        }
        const req = new CPSPreviewDto(contractId, authContext.defaultPlant, contractTypes, status, authContext.user.userName);
        service.approveOrRejectServiceContract(req).then(res => {
            if (res.status) {
                AlertMessages.getSuccessMessage(res.internalMessage);
                getServiceContractPreviewData();
            } else {
                AlertMessages.getErrorMessage(res.error);
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }

    const getExtraComponent = (status: any) => {
        if (status === ContractStatusEnum.DRAFT) {
            return <><Button onClick={() => sendForApproval()}>Send For Approval</Button></>
        }
        if (status === ContractStatusEnum.PENDING_APPROVAL) {
            return <span>
                {PermissionExist('Approve') && <Button size="small" onClick={() => {
                    approveOrRejectServiceContract(cpcId, ContractStatusEnum.ACTIVE, gridData?.contractTypes);
                }}>Approve</Button>}
                {PermissionExist('Reject') &&
                    <Button size="small" onClick={() => {
                        approveOrRejectServiceContract(cpcId, ContractStatusEnum.DRAFT, gridData?.contractTypes);
                    }}>Reject</Button>}
            </span>
        }
        if (status === ContractStatusEnum.ACTIVE && gridData?.contractTypes === ContractTypesEnum.ORIGINAL) {
            return <>{PermissionExist('Addendum') && <Button onClick={() => addendumClickHandler()}>Create Addendum</Button>}</>
        }
    }


    const fileUploadFormHandler = () => {
        filesFormRef.validateFields().then(fileValues => {
            getFilesData(fileValues.filesData).then(filesData => {
                service.addMoreFilesToCpsContracts({
                    filesData: filesData,
                    updatedUser: authContext.user.userName,
                    pcID: cpcId
                }).then(res => {
                    if (res.status) {
                        AlertMessages.getSuccessMessage(res.internalMessage);
                        getServiceContractPreviewData();
                        filesFormRef.resetFields();
                    } else {
                        AlertMessages.getErrorMessage(res.internalMessage);
                    }
                }).catch(err => {
                    AlertMessages.getErrorMessage(err.message);
                })
            });
        });
    }

    const valiDateWithSnapShotData = (keys: string[], value: string, isRawData: boolean): any => {
        for (const snapDate of snapData) {
            let valueAtGivenKey: any = snapDate;
            for (const key of keys) {
                valueAtGivenKey = valueAtGivenKey?.[key];
            }
            if (valueAtGivenKey) {
                if (keys.includes('contractDate')) {
                    valueAtGivenKey = moment(valueAtGivenKey).format('DD-MM-YYYY h:mm A')
                }
            }
            if (valueAtGivenKey == value) {
                return value;
            } else {
                if (gridData.contractTypes === ContractTypesEnum.ADDENDUM) {
                    if (isRawData) {
                        return value;
                    } else {
                        return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapDate.contractNo.replace('SNP', 'ADD')}><b>
                            {value}
                        </b></Tooltip></>
                    }
                }
            }
        };
        return value
    }

    const valiDateChildWithSnapShotChild = (qualitySeq: string, key: string, value: string): any => {
        const filterSeq = snapQuality?.find((e) => e.qualitySeq === qualitySeq)
        let valueAtGivenKey: any = filterSeq && filterSeq[key];

        if (valueAtGivenKey) {
            if (key === 'layCan') {
                valueAtGivenKey = laycanDateFormate(valueAtGivenKey)
            }
            if (valueAtGivenKey === value) {
                return value;
            } else {
                if (gridData.contractTypes === ContractTypesEnum.ADDENDUM) {
                    return <><s style={{ textDecorationColor: 'red', fontWeight: 'normal' }}>{valueAtGivenKey}</s>&nbsp;<Tooltip placement="top" title={snapData?.[0]?.contractNo?.replace('SNP', 'ADD')}><b>
                        {value}
                    </b></Tooltip></>
                }
            };
        }
        return value;//'hhhhhhhhhhhh'
    }

    const PermissionExist = (type: string) => {
        if (contractTypeCategory === ProcurementContractTypeEnum.Procurement_Fees) {
            return type === 'Approve' ? isPermissionExist([151]) : type === 'Reject' ? isPermissionExist([152]) : isPermissionExist([146]) // no feature id's for addendum todo in feature
        } else if (contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees) {
            return type === 'Approve' ? isPermissionExist([169]) : type === 'Reject' ? isPermissionExist([170]) : isPermissionExist([164])
        }
        return type === 'Approve' ? isPermissionExist([160]) : type === 'Reject' ? isPermissionExist([161]) : isPermissionExist([155])

    }


    const paymentTerms = { lc: gridData?.lc, others: gridData?.others, telegraphicTransfer: gridData?.telegraphicTransfer, lcValue: gridData?.lcValue, lcType: gridData?.lcType, lcCurrency: gridData?.lcCurrency, paymentMode: gridData?.paymentMode }

    const remarks: ColumnProps<any>[] = [
        {
            title: "Remarks",
            dataIndex: "remarks"
        }
    ]

    const link = `/#/detailCustomer?bp_id=${gridData?.vendorId}`
    const purchaseOrSalesDetailView = `/#${pathToreDirect}?${contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees ? "s" : "p"}c_id=${gridData?.purchaseContractNo}`
    const columns = ['LayCan', 'Quality', 'Purchase Type', 'Price Type', 'Quantity', 'Price', 'Total Price', 'QualityAdjustment']

    const rowClassName = (record, qualitySeq) => {
        if (!snapQuality.some((item) => item?.qualitySeq === qualitySeq)) {
            if (gridData?.contractTypes === ContractTypesEnum.ADDENDUM) {
                return 'highlighted-row';
            } else {
                return '';
            }
        }
        return '';
    };



    return (
        <>
            <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}>
                <Card
                    style={{ textAlign: 'center' }}
                    className='default-card-class'
                    title={<span style={{ color: 'white' }}> {gridData?.contractType} Contract -  {(gridData?.contractNo)}
                        <span style={{ paddingLeft: 20 }}> <StatusTag status={gridData?.status} />
                        </span>
                    </span>
                    }
                    extra={<>{getExtraComponent(gridData?.status)}</>}
                >
                    <Row>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"ContractType"}>
                                    {valiDateWithSnapShotData(['contractType'], gridData?.contractType, false)}
                                </Descriptions.Item>
                            </Descriptions></Col><Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"ContractDate"}>
                                    {valiDateWithSnapShotData(['contractDate'], gridData?.contractDate ? moment(gridData?.contractDate).format('DD-MM-YYYY h:mm A') : '', false)}</Descriptions.Item>
                            </Descriptions></Col>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={`${contractTypeCategory === ProcurementContractTypeEnum.Marketing_Fees ? "Sales Contract" : "Purchase Contract"}`} ><a><Link href={purchaseOrSalesDetailView}>
                                    {valiDateWithSnapShotData(['purchaseContract'], gridData?.purchaseContract, false)}
                                </Link></a></Descriptions.Item>
                            </Descriptions></Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"Vendor"}><span><Link href={link}>
                                    {valiDateWithSnapShotData(['vendor'], gridData?.vendor, false)}
                                </Link></span></Descriptions.Item>
                            </Descriptions></Col><Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"Currency"}>
                                    {valiDateWithSnapShotData(['currency'], gridData?.currency, false)}
                                </Descriptions.Item>

                            </Descriptions></Col>
                        <Col span={8}>
                            <Descriptions>

                                <Descriptions.Item label={"Approved By"}>
                                    {gridData?.approvedBy}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={"Approved Date"}>
                                    {gridData?.approvedOn ? moment(gridData?.approvedOn).format('DD-MM-YYYY h:mm A') : ''}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        {/* <Col span={8}>
                            <Descriptions>
                                <Descriptions.Item label={'Remarks'}>
                                    {valiDateWithSnapShotData(['remarks'], gridData?.remarks, false)}
                                </Descriptions.Item>
                            </Descriptions></Col> */}
                    </Row>
                    <Row>

                        <Descriptions>
                            <Descriptions.Item label={"AddendumData"}>
                                {gridData?.addendumData.map((rec) => {
                                    const link = `/#${detailViewPath}?cpcId=${rec.addendumContractId}`
                                    return <><a href={link} className="link-primary" >
                                        {`${rec.addendumContractNo} ,`}
                                    </a></>
                                })}
                            </Descriptions.Item>
                        </Descriptions>
                    </Row>

                    <br />

                    <Card>
                        <div className='table-responsive'>
                            <table className='table table-bordered table-sm'>
                                <thead className='table-header' style={{ background: '#fafafa' }}>
                                    {columns?.map((item) => (
                                        <th className='text-center'>{item}</th>
                                    ))}</thead>
                                <tbody>
                                    {qualities?.map((e, index) => {
                                        return (
                                            <tr key={e.id} style={{ backgroundColor: e.color ? e.color : "" }} className={rowClassName(e, e.qualitySeq)}>
                                                <td className='text-uppercase text-center font-bold' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'layCan', laycanDateFormate(e?.layCan))}</td>
                                                <td className='text-uppercase text-center font-bold' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'purchaseQuality', e?.purchaseQuality)}</td>
                                                <td className='text-uppercase text-center font-bold' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'pcPurchaseType', e?.pcPurchaseType)}</td>
                                                <td className='text-uppercase text-center' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'priceType', e?.priceType)}</td>
                                                <td className='text-uppercase text-center font-bold' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'quantityInMT', e?.quantityInMT)}</td>

                                                <td className='text-uppercase text-center' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'pricePMT', e?.pricePMT)}</td>
                                                <td className='text-uppercase text-center' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'totalPrice', e?.totalPrice)}</td>
                                                <td className='text-uppercase text-center' style={{ verticalAlign: 'middle' }} >{valiDateChildWithSnapShotChild(e.qualitySeq, 'qualityAdjustment', e?.qualityAdjustment)}</td>

                                            </tr>
                                        )
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Card>

                    <Card >
                        <PaymentTermsView paymentTerms={paymentTerms} />
                    </Card>
                    <Card>
                        <Table dataSource={Array(gridData)} columns={remarks}
                            pagination={false} /></Card>

                    {gridData?.filesData &&
                        <FileUploadForm maxCount={3} layoutType='vertical' initialValues={gridData?.filesData} formRef={filesFormRef} >
                            <Row justify="end">
                                <Col>
                                    {
                                        <Space>
                                            <Button
                                                onClick={fileUploadFormHandler}
                                                type="primary">
                                                Save and Next
                                            </Button>
                                        </Space>
                                    }</Col>
                            </Row>
                        </FileUploadForm>
                    }
                </Card>
                <Affix offsetBottom={0}>
                    <Card bodyStyle={{ padding: '6px 12px' }}>
                        <Row justify='space-between'>
                            <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
                            <Col>{(gridData?.['status'] === ContractStatusEnum.DRAFT || gridData?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && <Button onClick={() => {
                                const link = `${pcUpdatePath}?cpcId=${cpcId}`
                                navigate(link);
                            }}>Edit</Button>}</Col>
                        </Row>
                    </Card>
                </Affix>
            </Layout >
        </>
    );

}
export default ProcurementContractDetailedView;