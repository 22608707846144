import { DownOutlined } from '@ant-design/icons';
import {
  AdvanceEnum,
  BusinessPartnerBpIdDto,
  BusinessPartnerService,
  ContractIdRequest,
  ContractStatusEnum,
  CurrencyConverterService,
  CurrencyExchangeRequestDto,
  CurrencyService,
  PurchaseContractService,
  VendorAdvService,
} from '@exportx/shared-models-and-services';
import {
  Button,
  Card,
  Col,
  Collapse,
  Dropdown,
  Form,
  MenuProps,
  Row,
  Space,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AlertMessages,
  PreviewComponent,
  useAuthState,
} from '../../../../common';
import VendorAdvance from './vendoradvance-form';

const { Panel } = Collapse;
const VendorAdvancePreview = () => {
  const { formatMessage: fm } = useIntl();
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const { authContext } = useAuthState();

  const [defaultOpenKey, setDefaultOpenKey] = useState(['1']);
  const [vendorAdvanceData, setVendorAdvanceData] = useState<any>();
  const [vendorAdvanceFormRef] = Form.useForm();
  const service = new VendorAdvService();

  const [bpName, setBpName] = useState<any>();
  const [contractNo, setContractNo] = useState<any>();
  const [currencyName, setCurrencyName] = useState<any>();
  const [negotiationCurrency, setNegotiationCurrency] = useState<any>();
  const [goToButtonKeys, setGoToButtonKeys] = useState([]);

  const businessPartnerService = new BusinessPartnerService();
  const PurchaseContract = new PurchaseContractService();
  const currencyService = new CurrencyService();
  const currencyConverterService = new CurrencyConverterService();

  let pathToreDirect = '/vendor-advance-against-contract';
  if (
    vendorAdvanceFormRef.getFieldValue('contractType') ===
    AdvanceEnum.VendorAdvance
  ) {
    pathToreDirect = '/vendor-advance';
  } else if (
    vendorAdvanceFormRef.getFieldValue('contractType') ===
    AdvanceEnum.DownPayment
  ) {
    pathToreDirect = '/down-payment';
  }

  const { record } = state ? state : { record: null };
  const COLUMN_STYLE = { width: 2000 };

  useEffect(() => {
    let limit = Number(defaultOpenKey[0]);
    let i: number,
      goto: number[] = [];
    for (i = 1; i < limit; i++) {
      goto.push(i);
    }
    setGoToButtonKeys(goto);
  }, [defaultOpenKey]);

  // console.log('state', state)

  useEffect(() => {
    if (state) {
      setDefaultData();
    }
  }, [state]);

  useEffect(() => {
    let limit = Number(defaultOpenKey[0]);
    let i: number,
      goto: number[] = [];
    for (i = 1; i < limit; i++) {
      goto.push(i);
    }
    setGoToButtonKeys(goto);
  }, [defaultOpenKey]);

  const setDefaultData = () => {
    if (state.vendorAdvData) {
      const newState = { ...state.vendorAdvData };
      newState.agreementDate = moment(state.contractDate);
      if (
        newState.jisdorDate &&
        newState.negotiationCurrency &&
        newState.billingCurrency
      ) {
        const req = new CurrencyExchangeRequestDto(
          newState.negotiationCurrency,
          newState.billingCurrency,
          moment(newState.jisdorDate).format('YYYY-MM-DD'),
        );
        currencyConverterService
          .getCurrencyExchangeRate(req)
          .then((res) => {
            if (res.status) {
              newState['displayExchangeRate'] = res?.data?.displayExchangeRate;
            } else {
              newState['displayExchangeRate'] = res?.data?.displayExchangeRate;
            }
            setVendorAdvanceData(newState);
          })
          .catch((err) => {
            console.log('error:' + err.message);
            setVendorAdvanceData(newState);
          });
      } else {
        setVendorAdvanceData(newState);
      }
    }
  };

  const VendorAdvanceFormhandler = () => {
    //TODO:create perfect DTO
    vendorAdvanceFormRef
      .validateFields()
      .then((values) => {
        setVendorAdvanceData((prevState) => {
          return {
            ...prevState,
            ...values,
            versionFlag: vendorAdvanceData?.versionFlag,
            paymentTerms: {
              paymentMode: values?.paymentMode,
              lc: values?.lc,
              lcType: values?.lcType,
              lcValue: values?.lcValue,
              others: values?.others,
              telegraphicTransfer: values?.telegraphicTransfer,
              actLedger: values.actLedger,
            },
            jisdorDate: values.jisdorDate,
          };
        });
        setDefaultOpenKey(['2']);
      })
      .catch((errorInfo) => {
        errorInfo.errorFields.map((item) =>
          console.log(item.name[0], ' is a mandatory field'),
        );
      });
  };
  console.log('vendorAdavnce dataaaaa', vendorAdvanceData);

  useEffect(() => {
    if (vendorAdvanceFormRef.getFieldValue('vendorId')) {
      getBpName();
    }
  }, [vendorAdvanceFormRef.getFieldValue('vendorId')]);

  const getBpName = () => {
    const req = new BusinessPartnerBpIdDto(
      vendorAdvanceFormRef.getFieldValue('vendorId'),
    );
    businessPartnerService
      .getBpName(req)
      .then((res) => {
        if (res.bpName) {
          setBpName(res.bpName);
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  useEffect(() => {
    if (vendorAdvanceFormRef.getFieldValue('purchaseContractNo')) {
      getContractNo();
    }
  }, [vendorAdvanceFormRef.getFieldValue('purchaseContractNo')]);

  const getContractNo = () => {
    const req = new ContractIdRequest(
      vendorAdvanceFormRef.getFieldValue('purchaseContractNo'),
      authContext.defaultPlant,
    );
    PurchaseContract.getContractNo(req)
      .then((res) => {
        if (res.contractNo) {
          setContractNo(res.contractNo);
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  useEffect(() => {
    if (vendorAdvanceFormRef.getFieldValue('billingCurrency')) {
      getCurrencyName();
    }
  }, [vendorAdvanceFormRef.getFieldValue('billingCurrency')]);

  const getCurrencyName = () => {
    currencyService
      .getCurrncyName({
        currencyId: vendorAdvanceFormRef.getFieldValue('billingCurrency'),
      })
      .then((res) => {
        setCurrencyName(res.currencyCode);
      })
      .catch((err) => {
        console.log('error:' + err.message);
      });
  };

  useEffect(() => {
    if (vendorAdvanceFormRef.getFieldValue('negotiationCurrency')) {
      getNegotiationCurrency();
    }
  }, [vendorAdvanceFormRef.getFieldValue('negotiationCurrency')]);

  const getNegotiationCurrency = () => {
    currencyService
      .getCurrncyName({
        currencyId: vendorAdvanceFormRef.getFieldValue('negotiationCurrency'),
      })
      .then((res) => {
        setNegotiationCurrency(res.currencyName);
      })
      .catch((err) => {
        console.log('error:' + err.message);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'PurchaseContractNo',
      dataIndex: 'contractNo',
      width: '30%',
      align: 'center',
    },

    {
      title: 'Vendor',
      dataIndex: 'bpName',
      width: '40%',
      align: 'center',
    },
    {
      title: 'Billing Currency',
      dataIndex: 'currencyName',
      width: '30%',
      align: 'center',
    },
    // {
    //   title: 'Negotiation Currency',
    //   dataIndex: 'systemCurrency',
    //   width: '30%',
    //   align: 'center',

    // },
  ];

  const getTableData = () => {
    const tableDAta = [
      { currencyName: currencyName, contractNo: contractNo, bpName: bpName },
    ];
    return tableDAta;
  };

  const previewDataLabels = [
    {
      title: fm({
        id: `step1heading`,
        defaultMessage: `${state?.contract} Details`,
      }),
      keysAndLabelMapping: [
        {
          key: 'VendorAdvance',
          labels: {
            contractDate: fm({
              id: 'mdm.components.vendorAdvance.contractDate',
              defaultMessage: 'Contract Date',
            }),
            advanceAmount: fm({
              id: 'mdm.components.vendorAdvance.advanceAmount',
              defaultMessage: 'Advance Amount',
            }),
            displayExchangeRate: fm({
              id: 'mdm.components.vendorAdvance.exchangeRate',
              defaultMessage: 'Exchange Rate',
            }),
            jisdorDate: fm({
              id: 'mdm.components.vendorAdvance.jisdorDate',
              defaultMessage: 'JISDOR Date',
            }),
            amount: fm({
              id: 'mdm.components.vendorAdvance.amount',
              defaultMessage: 'Amount',
            }),
            deductionType: fm({
              id: 'mdm.components.vendorAdvance.deductionType',
              defaultMessage: 'Deduction Type',
            }),
            pricePMT: fm({
              id: 'mdm.components.vendorAdvance.pricePMT',
              defaultMessage: 'PricePMT',
            }),
            lumpSum: fm({
              id: 'mdm.components.vendorAdvance.lumpSum',
              defaultMessage: 'LumpSum',
            }),
            deductionCategory: fm({
              id: 'mdm.components.vendorAdvance.deductionCategory',
              defaultMessage: 'DeductionCategory',
            }),
            remarks: fm({
              id: 'mdm.components.vendorAdvance.remarks',
              defaultMessage: 'Remarks',
            }),
          },
        },
      ],
      isGridDisplay: false,
    },
  ];

  const clearVendorAdvance = () => {
    vendorAdvanceFormRef.resetFields();
  };

  const AdvanceUpdate = () => {
    if (
      state.vendorAdvData &&
      state.vendorAdvData.status === ContractStatusEnum.ACTIVE
    ) {
      service
        .createVendorAdvAddendum({
          ...vendorAdvanceData,
          plantCode: authContext.defaultPlant,
          updatedUser: authContext.user.userName,
        })
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(
              `${state?.contractTypes} Updated Successfully`,
            );
            setTimeout(() => {
              navigate(pathToreDirect, { state: { record: record } });
            }, 1000);
          }
        })
        .catch((err) => {
          AlertMessages.getErrorMessage(err.message);
        });
    } else {
      service
        .create({
          ...vendorAdvanceData,
          plantCode: authContext.defaultPlant,
          createdUser: authContext.user.userName,
        })
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(
              `${state.contract} created SuccessFully`,
            );
            setTimeout(() => {
              navigate(pathToreDirect);
            }, 1000);
          }
        })
        .catch((err) => {
          AlertMessages.getErrorMessage(err.message);
        });
    }
  };

  const goToOnClickHandler: MenuProps['onClick'] = ({ key }) => {
    setDefaultOpenKey([`${key}`]);
  };

  const items = goToButtonKeys.map((item) => {
    return {
      label: `${item}`,
      key: `${item}`,
    };
  });
  return (
    <div>
      <Collapse activeKey={defaultOpenKey}>
        <Panel header="Advance" key="1">
          <VendorAdvance
            formRef={vendorAdvanceFormRef}
            initialValues={vendorAdvanceData}
            layoutType="vertical"
          >
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button
                      onClick={() => {
                        clearVendorAdvance();
                      }}
                    >
                      Clear
                    </Button>
                    <Button onClick={VendorAdvanceFormhandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }
              </Col>
            </Row>
          </VendorAdvance>
        </Panel>
        <Panel header="Preview" key="2">
          <>
            <PreviewComponent
              data={{ VendorAdvance: vendorAdvanceData }}
              labels={previewDataLabels}
              primaryKeyData={{}}
            >
              <Row justify="end">
                <Col>
                  <div>
                    <Card bodyStyle={{ overflow: 'auto' }}>
                      <Table
                        columns={columns}
                        dataSource={getTableData()}
                        pagination={false}
                        bordered={true}
                        style={COLUMN_STYLE}
                      />
                    </Card>
                  </div>
                  <br />
                  {
                    <Row justify="end">
                      <Space>
                        <Dropdown.Button
                          icon={<DownOutlined />}
                          menu={{ items, onClick: goToOnClickHandler }}
                        >
                          {fm({ id: 'common.goTo', defaultMessage: 'Go To' })}
                        </Dropdown.Button>
                        <Button onClick={AdvanceUpdate} type="primary">
                          Save
                        </Button>
                      </Space>
                    </Row>
                  }
                </Col>
              </Row>
            </PreviewComponent>
          </>
        </Panel>
      </Collapse>
    </div>
  );
};

export default VendorAdvancePreview;






