
import { ColaIndexTypeEnum, ContractModesEnum, ContractTermEnum, ContractTypesEnum, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, PaymentModesEnum, PurchaseContractDto, SalesContractService, SpecTypesEnum, TaxesAndItemsService } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Collapse, Dropdown, Form, Input, Menu, MenuProps, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { DownOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, FileUploadForm, useAuthState, } from '../../../common';
import { SpecInitialData } from '../creation-forms/specs/initial-spec-data';
import { AddQuality } from '../creation-forms/add-quality';
import { CommercialDocuments } from '../creation-forms/commercial-documents';
import { ContractDetail } from '../creation-forms/contract-details';
import { PaymentTerm } from '../creation-forms/payment-terms';
import { ShippingTerms } from '../creation-forms/shipping-terms';
import { SurveyorDetails } from '../creation-forms/surveyor-details';
import { getFilesData } from '@exportx/ui-utils';
import CommercialDocumentsView from '../view-components/commercial-documents';
import ContractDetailsView from '../view-components/contract-details-view';
import PaymentTermsView from '../view-components/payment-terms-view';
import QualityDetailView from '../view-components/quality-detail-view';
import ShippingTermsView from '../view-components/shipping-terms-view';
import SurveyorDetailsView from '../view-components/surveyor-details-view';
import { filePermissions } from '@exportx/ui-utils/src/utils/file-permissions';
import { SaveAndNextButton } from '../creation-forms';


export interface SalesContractProps {
  soAction?: string
}

const { Panel } = Collapse;
const { TextArea } = Input;
export const SalesContract = (props?: SalesContractProps) => {
  const { authContext } = useAuthState();
  const navigate = useNavigate();
  const { state, pathname }: any = useLocation();

  //Common for all Page Components
  const { formatMessage: fm } = useIntl();
  const [defaultActiveKey, setDefaultActiveKey] = useState(['1']);
  const [goToButtonKeys, setGoToButtonKeys] = useState([]);
  const [purchaseData, setPurchaseData] = useState<any>({
    contractDetails: {},
    qualityDetails: { qualityData: [{ purchaseTypeData: [''] }] },
    surveyorDetails: [],
    commercialDocuments: { commercialDocuments: ['', '', '', '', ''] },
    shippingTerms: { shippingTerms: ['', '', '', ''] },
    paymentTerms: { paymentMode: PaymentModesEnum.TELEGRAPHIC_TRANSFER, paymentTerms: ['', '', '', ''] },
    filesData: [],
    remarks: '',
    addendumData: []
  });
  const [snapData, setSnapData] = useState<PurchaseContractDto[]>([]);

  const [purchaseTermData, setPurchaseTermData] = useState({});
  const [activeQualityKeyTerm, setActiveQualityKeyTerm] = useState<number>(null);
  const [purchaseTypeData, setPurchaseTypeData] = useState<any>({ '0$&0': { purchaseType: undefined, isIndexed: false, data: { coalIndexType: ColaIndexTypeEnum.WEEKLY } } });
  const [activeIndexForm, setActiveIndexForm] = useState(undefined);
  const [activeIndexGrade, setActiveIndexGrade] = useState(undefined);



  const [contractDetailsFormRef] = Form.useForm();
  const [qualityDetailsFormRef] = Form.useForm();
  const [surveyorDetailsFormRef] = Form.useForm();
  const [CommercialDocumentsFormRef] = Form.useForm();
  const [shippingTermsFormRef] = Form.useForm();
  const [PaymentTermFormRef] = Form.useForm();
  const [filesFormRef] = Form.useForm();
  const [remarksFormRef] = Form.useForm();
  const [vat, setVat] = useState<GetTaxesByBpIdDto[]>([]);
  const taxesAndItemsService = new TaxesAndItemsService();

  let filePermission;
  useEffect(() => {
    if (props.soAction == 'addendum') {
      filePermission = filePermissions.salesContracts.addendum
    } else if (props.soAction == 'create') {
      filePermission = filePermissions.salesContracts.contract
    } else {
      filePermission = filePermissions.salesContracts.others
    }
  })

  useEffect(() => {
    let limit = Number(defaultActiveKey[0]);
    let i: number, goto: number[] = [];
    for (i = 1; i < limit; i++) {
      goto.push(i);
    }
    setGoToButtonKeys(goto);
  }, [defaultActiveKey])

  const service = new SalesContractService();
  let pathToreDirect = '/so-tracker';

  useEffect(() => {
    if (state) {
      setDefaultData();
      if (pathname.includes('addendum')) {
        setSnapData([state])
      }
    }
  }, [state, pathname]);

  const getTaxesByItemsId = (contractDate) => {
    const req = new ItemsIdRequestDto(ItemsCodesEnum.COAL, contractDate)
    taxesAndItemsService.getVatByItemsId(req).then(res => {
      if (res.status) {
        setVat(res.data);
      } else {
        setVat([]);
      }
    }).catch(err => {
      console.log(err);
      setVat([]);
    });
  }

  const setDefaultData = () => {
    if (state) {
      setPurchaseData(state);
      state.qualityDetails.qualityData.forEach((quality: any, index: number) => {
        purchaseTermHandler(index, quality.purchasingTerm, quality.purchaseTermData, quality.specStandard, quality.qualityAdjustments, quality.noPriceAdjustment);
        quality.purchaseTypeData.forEach((purchaseType, pcIndex) => {
          setPurchaseTypeData(prevState => {
            const newState = { ...prevState };
            newState[`${index}$&${pcIndex}`] = {
              ...prevState[`${index}$&${pcIndex}`] ? prevState[`${index}$&${pcIndex}`] : {},
              isIndexed: purchaseType['isIndexLinked'],
              purchaseType: purchaseType.purchaseType,
              data: { ...purchaseType['indexedData'] }
            };
            return newState;
          });
        });
      });
    }
  }

  const PurchaseContractFormHandler = () => {
    contractDetailsFormRef.validateFields().then(values => {
      setPurchaseData((PrevState) => { return { ...PrevState, contractDetails: { ...PrevState.contractDetails, ...values, createdUser: authContext.user.userName, updatedUser: authContext.user.userName, versionFlag: purchaseData.contractDetails.versionFlag, } } });
      setDefaultActiveKey(['2']);
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], 'is a mandatory field'));
    })
  }

  const addQualityFormHandler = () => {
    qualityDetailsFormRef.validateFields().then(values => {
      setPurchaseData((prevState) => { return { ...prevState, qualityDetails: values } });
      setDefaultActiveKey(['3']);
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const surveyorDetailsFormHandler = () => {
    surveyorDetailsFormRef.validateFields().then(values => {
      setPurchaseData((prevState) => { return { ...prevState, surveyorDetails: values.surveyorData } });
      setDefaultActiveKey(['4']);
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const commercialDocumentsFormHandler = () => {
    CommercialDocumentsFormRef.validateFields().then(values => {
      setPurchaseData((prevState) => { return { ...prevState, commercialDocuments: values } });
      setDefaultActiveKey(['7']);
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const shippingTermsFormHandler = () => {
    shippingTermsFormRef.validateFields().then(values => {
      setPurchaseData((prevState) => { return { ...prevState, shippingTerms: values } });
      setDefaultActiveKey(['5']);
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const paymentTermFormHandler = () => {
    PaymentTermFormRef.validateFields().then(values => {
      setPurchaseData((prevState) => { return { ...prevState, paymentTerms: values } });
      setDefaultActiveKey(['6']);
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => {
      getFilesData(fileValues.filesData).then(filesData => {
        setPurchaseData((prevState) => {
          const requestObject = { ...prevState };
          prevState.qualityDetails.qualityData.forEach((rec, index) => {
            let specData = {};
            if (rec['specStandard'] === SpecTypesEnum.ASTM) {
              specData['astmData'] = purchaseTermData[`${index}`]['purchaseTermData']['astmData'];
            } else if (rec['specStandard'] === SpecTypesEnum.ISO) {
              specData['isoData'] = purchaseTermData[`${index}`]['purchaseTermData']['isoData'];
            } else {
              specData['astmData'] = purchaseTermData[`${index}`]['purchaseTermData']['astmData'];
              specData['isoData'] = purchaseTermData[`${index}`]['purchaseTermData']['isoData'];
            }
            rec.purchaseTypeData.forEach((purchaseRec, purchaseIndex) => {
              if (purchaseRec.isIndexLinked) {
                const indexedData = purchaseTypeData[`${index}$&${purchaseIndex}`].data;
                rec.purchaseTypeData[purchaseIndex] = { ...purchaseRec, indexedData }
              }
            });
            requestObject.qualityDetails.qualityData[index] = { ...rec, purchaseTermData: specData }
          });
          requestObject.remarks = fileValues.remarks;
          requestObject.plantCode = authContext.defaultPlant;
          return { ...requestObject, filesData: [...filesData] }
        });
        setDefaultActiveKey(['8']);
      });
    });
  }

  const getSpecStandardToBeShow = (qualityAdjustments: any, noPriceAdjustment: any) => {
    return `${qualityAdjustments} ${noPriceAdjustment ? ' - No Price Adjustment' : ''}`;
  }

  const purchaseTermHandler = (qualityKey: number, selectedPurchasingTerm: ContractTermEnum, purchaseTermDataClone?: any, specSpecification?: any, qualityAdjustments?: any, noPriceAdjustment?: any) => {
    setPurchaseTermData(prevState => {
      const newState = { ...prevState };
      newState[qualityKey] = {
        purchaseTerm: selectedPurchasingTerm,
        purchaseTermData: purchaseTermDataClone ? purchaseTermDataClone : newState[qualityKey]?.['purchaseTermData'] ? newState[qualityKey]['purchaseTermData'] : { astmData: SpecInitialData, isoData: SpecInitialData },
        specSpecification: specSpecification ? specSpecification : newState[qualityKey]?.['specSpecification'] ? newState[qualityKey]['specSpecification'] : SpecTypesEnum.ASTM,
        qualityAdjustments: qualityAdjustments ? qualityAdjustments : newState[qualityKey]?.['qualityAdjustments'] ? newState[qualityKey]['qualityAdjustments'] : null,
        noPriceAdjustment: noPriceAdjustment ? noPriceAdjustment : newState[qualityKey]?.['noPriceAdjustment'] ? newState[qualityKey]['noPriceAdjustment'] : false,
        specStandardToBeShow: getSpecStandardToBeShow(specSpecification ? specSpecification : newState[qualityKey]?.['specSpecification'] ? newState[qualityKey]['specSpecification'] : SpecTypesEnum.ASTM, noPriceAdjustment ? noPriceAdjustment : newState[qualityKey]?.['noPriceAdjustment'] ? newState[qualityKey]['noPriceAdjustment'] : false)
      };
      return newState;
    });
    if (!specSpecification)
      setActiveQualityKeyTerm(qualityKey);
  }

  const purchaseTermFormSubmitHandler = (formValues: any) => {
    setPurchaseTermData(prevState => {
      const newState = { ...prevState };
      const qualityKey = activeQualityKeyTerm;
      newState[qualityKey] = {
        purchaseTerm: prevState[qualityKey]['purchaseTerm'],
        purchaseTermData: { ...prevState[qualityKey]['purchaseTermData'], astmData: { ...formValues['astmData'] }, isoData: { ...formValues['isoData'] } },
        specSpecification: formValues['specSpecification'],
        qualityAdjustments: formValues['qualityAdjustments'],
        noPriceAdjustment: formValues['noPriceAdjustment'],
        specStandardToBeShow: getSpecStandardToBeShow(formValues['specSpecification'], formValues['noPriceAdjustment'])
      };
      return newState;
    });
    qualityDetailsFormRef.setFields([
      {
        name: ['qualityData', activeQualityKeyTerm, 'specStandard'],
        value: formValues['specSpecification'],
      },
      {
        name: ['qualityData', activeQualityKeyTerm, 'qualityAdjustments'],
        value: formValues['qualityAdjustments'],
      },
      {
        name: ['qualityData', activeQualityKeyTerm, 'noPriceAdjustment'],
        value: formValues['noPriceAdjustment'],
      },
      {
        name: ['qualityData', activeQualityKeyTerm, 'specStandardToBeShow'],
        value: getSpecStandardToBeShow(formValues['specSpecification'], formValues['noPriceAdjustment']),
      }
    ]);
    setActiveQualityKeyTerm(null);
  }

  const handleIsIndexedButton = (isIndexChecked: boolean, purchaseTypeKey: string) => {
    const presentFormData = qualityDetailsFormRef.getFieldValue(['qualityData', purchaseTypeKey.split('$&')[0], 'purchaseTypeData']);
    const activeIndexGrade = qualityDetailsFormRef.getFieldValue(['qualityData', purchaseTypeKey.split('$&')[0], 'quality']);
    const activeIndexLayCan = qualityDetailsFormRef.getFieldValue(['qualityData', purchaseTypeKey.split('$&')[0], 'layCan']);
    if (activeIndexGrade && activeIndexLayCan) {
      presentFormData[purchaseTypeKey.split('$&')[1]].isIndexLinked = isIndexChecked;
      qualityDetailsFormRef.setFields([
        {
          name: ['qualityData', purchaseTypeKey.split('$&')[0], 'purchaseTypeData'],
          value: presentFormData,
        },
      ]);
      setPurchaseTypeData(prevState => {
        const newState = { ...prevState };
        newState[purchaseTypeKey] = {
          ...prevState[purchaseTypeKey],
          purchaseType: prevState[purchaseTypeKey]?.purchaseType,
          isIndexed: isIndexChecked,
        };
        return newState;
      });
      setActiveIndexForm(isIndexChecked ? purchaseTypeKey : undefined);
      setActiveIndexGrade(activeIndexGrade);
    } else {
      const msg = (!(activeIndexGrade && activeIndexLayCan)) ? 'Please select Laycan and enter Quality' : activeIndexGrade ? 'Please Select Laycan' : 'Please enter Quality';
      AlertMessages.getErrorMessage(msg);
      presentFormData[purchaseTypeKey.split('$&')[1]].isIndexLinked = false;
      qualityDetailsFormRef.setFields([
        {
          name: ['qualityData', purchaseTypeKey.split('$&')[0], 'purchaseTypeData'],
          value: presentFormData,
        },
      ]);
      setPurchaseTypeData(prevState => {
        const newState = { ...prevState };
        newState[purchaseTypeKey] = {
          ...prevState[purchaseTypeKey],
          purchaseType: prevState[purchaseTypeKey]?.purchaseType,
          isIndexed: false,
        };
        return newState;
      });
    }

  }


  const handlePurchaseType = (shippingTerm: string, purchaseTypeKey: string) => {
    setPurchaseTypeData(prevState => {
      const newState = { ...prevState };
      newState[purchaseTypeKey] = {
        ...prevState[purchaseTypeKey],
        purchaseType: shippingTerm,
      };
      return newState;
    });
  }

  const indexFormCancelHandler = () => {
    const presentFormData = qualityDetailsFormRef.getFieldValue(['qualityData', activeIndexForm.split('$&')[0], 'purchaseTypeData']);
    presentFormData[activeIndexForm.split('$&')[1]].isIndexLinked = false;
    qualityDetailsFormRef.setFields([
      {
        name: ['qualityData', activeIndexForm.split('$&')[0], 'purchaseTypeData'],
        value: presentFormData,
      },
    ]);
    setPurchaseTypeData(prevState => {
      const newState = { ...prevState };
      newState[activeIndexForm] = {
        purchaseType: prevState[activeIndexForm]?.purchaseType,
        isIndexed: false,
        data: { coalIndexType: ColaIndexTypeEnum.WEEKLY }
      };
      return newState;
    });
    setActiveIndexForm(undefined);
    setActiveIndexGrade(undefined);
  }

  const indexFormSubmitButtonHandler = (indexedFormValues: any) => {
    const presentFormData = qualityDetailsFormRef.getFieldValue(['qualityData', activeIndexForm.split('$&')[0], 'purchaseTypeData']);
    presentFormData[activeIndexForm.split('$&')[1]].coalPricePMT = indexedFormValues?.finalCoalBasePricePMT ?? 0;
    qualityDetailsFormRef.setFields([
      {
        name: ['qualityData', activeIndexForm.split('$&')[0], 'purchaseTypeData'],
        value: presentFormData,
      },
    ]);

    setPurchaseTypeData(prevState => {
      const newState = { ...prevState };
      newState[activeIndexForm] = {
        ...prevState[activeIndexForm],
        isIndexed: true,
        data: { ...indexedFormValues }
      };
      return newState;
    });
    setActiveIndexForm(undefined);
    setActiveIndexGrade(undefined);
  }

  const savePurchaseContract = () => {
    const requestObject = { ...purchaseData };
    purchaseData.qualityDetails.qualityData.forEach((rec, index) => {
      let specData = {};
      if (rec['specStandard'] === SpecTypesEnum.ASTM) {
        specData['astmData'] = purchaseTermData[`${index}`]['purchaseTermData']['astmData'];
      } else if (rec['specStandard'] === SpecTypesEnum.ISO) {
        specData['isoData'] = purchaseTermData[`${index}`]['purchaseTermData']['isoData'];
      } else {
        specData['astmData'] = purchaseTermData[`${index}`]['purchaseTermData']['astmData'];
        specData['isoData'] = purchaseTermData[`${index}`]['purchaseTermData']['isoData'];
      }
      rec.purchaseTypeData.forEach((purchaseRec, purchaseIndex) => {
        if (purchaseRec.isIndexLinked) {
          const indexedData = purchaseTypeData[`${index}$&${purchaseIndex}`].data;
          rec.purchaseTypeData[purchaseIndex] = { ...purchaseRec, indexedData }
        }
      });
      requestObject.qualityDetails.qualityData[index] = { ...rec, purchaseTermData: specData }
    });
    requestObject.remarks = remarksFormRef.getFieldValue('remarks');
    requestObject.plantCode = authContext.defaultPlant;
    if (state) {
      if (props?.soAction === 'addendum') {
        requestObject.soAction = props.soAction
        createSaleOrderAddendum(requestObject)
      } else {
        updateSaleOrder(requestObject);
      }
    }
    else
      createSaleOrder(requestObject);
  }

  const createSaleOrder = (requestObject: any) => {
    service.createSalesContract(requestObject).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Sales Contract created SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const updateSaleOrder = (requestObject: any) => {
    service.updateSaleOrder(requestObject).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Sales Contract Updated SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const createSaleOrderAddendum = (requestObject) => {
    service.createSaleOrderAddendum(requestObject).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: `Sales Contract Addendum added SuccessFully` }));
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const goToOnClickHandler: MenuProps['onClick'] = ({ key }) => {
    setDefaultActiveKey([`${key}`]);
  };





  const menu = (
    <Menu
      onClick={goToOnClickHandler}
      items={
        goToButtonKeys.map(item => {
          return {
            label: `${item}`,
            key: `${item}`,
          }
        })}
    />
  );


  return (
    <div>
      <Collapse activeKey={defaultActiveKey} collapsible='disabled' >
        <Panel header="Contract Details" key="1">
          <ContractDetail formRef={contractDetailsFormRef} initialValues={purchaseData.contractDetails} layoutType='vertical' contractType={ContractModesEnum.SALES}>
            <Row justify="end">
              <Col>
                <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={PurchaseContractFormHandler} />
                {/* {
                  <Space>
                    <Dropdown.Button
                      icon={<DownOutlined />}
                      overlay={menu}
                    >
                      {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                    </Dropdown.Button>
                    <Button onClick={PurchaseContractFormHandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                } */}
              </Col>
            </Row>
          </ContractDetail>
        </Panel>
        <Panel header="Quality & Price Details" key="2">
          <AddQuality formRef={qualityDetailsFormRef} initialValues={purchaseData.qualityDetails} layoutType='vertical' purchaseTermHandler={purchaseTermHandler} purchaseTermFormSubmitHandler={purchaseTermFormSubmitHandler} handleIsIndexedButton={handleIsIndexedButton} handlePurchaseType={handlePurchaseType} indexFormCancelHandler={indexFormCancelHandler} indexFormSubmitButtonHandler={indexFormSubmitButtonHandler} activeIndexForm={activeIndexForm} purchaseTypeData={purchaseTypeData} activeQualityKeyTerm={activeQualityKeyTerm} setPurchaseTermData={setPurchaseTermData} setPurchaseTypeData={setPurchaseTypeData} purchaseTermData={purchaseTermData} activeIndexGrade={activeIndexGrade} contractType={ContractModesEnum.SALES}>
            <Row justify="end">
              <Col>
                <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={addQualityFormHandler} />
                {/* {
                  <Space>
                    <Dropdown.Button
                      icon={<DownOutlined />}
                      overlay={menu}
                    >
                      {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                    </Dropdown.Button>
                    <Button onClick={addQualityFormHandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                } */}
              </Col>
            </Row>
          </AddQuality>
        </Panel>
        <Panel header="Surveyor Details" key="3">
          <SurveyorDetails
            formRef={surveyorDetailsFormRef}
            initialValues={purchaseData.surveyorDetails}
            layoutType='vertical'>
            <Row justify="end">
              <Col>
                <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={surveyorDetailsFormHandler} />
                {/* {
                  <Space>
                    <Dropdown.Button
                      icon={<DownOutlined />}
                      overlay={menu}
                    >
                      {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                    </Dropdown.Button>
                    <Button onClick={surveyorDetailsFormHandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                } */}
              </Col>
            </Row>
          </SurveyorDetails>
        </Panel>
        <Panel header="Shipping And Loading Terms" key="4">
          <ShippingTerms
            formRef={shippingTermsFormRef} initialValues={purchaseData.shippingTerms}
            layoutType='vertical'>
            <Row justify="end">
              <Col>
                <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={shippingTermsFormHandler} />
                {/* {
                  <Space>
                    <Dropdown.Button
                      icon={<DownOutlined />}
                      overlay={menu}
                    >
                      {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                    </Dropdown.Button>
                    <Button onClick={shippingTermsFormHandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                } */}
              </Col>
            </Row>
          </ShippingTerms>
        </Panel>

        <Panel header="Payment Term" key="5">
          <PaymentTerm formRef={PaymentTermFormRef} initialValues={purchaseData.paymentTerms} layoutType='vertical'>
            <Row justify="end">
              <Col>
                <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={paymentTermFormHandler} />
                {/* {
                  <Space>
                    <Dropdown.Button
                      icon={<DownOutlined />}
                      overlay={menu}
                    >
                      {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                    </Dropdown.Button>
                    <Button onClick={paymentTermFormHandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                } */}
              </Col>
            </Row>
          </PaymentTerm>
        </Panel>
        <Panel header="Commercial And Documents" key="6">
          <CommercialDocuments formRef={CommercialDocumentsFormRef} initialValues={purchaseData.commercialDocuments} />
          <Row justify="end">
            <Col>
              <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={commercialDocumentsFormHandler} />
              {/* {
                <Space>
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    overlay={menu}
                  >
                    {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                  </Dropdown.Button>
                  <Button onClick={commercialDocumentsFormHandler} type="primary">
                    Save and Next
                  </Button>
                </Space>
              } */}
            </Col>
          </Row>
        </Panel>
        <Panel header="Remarks & Upload Documents" key="7">
          <FileUploadForm maxCount={3} layoutType='vertical' formRef={filesFormRef} initialValues={props?.soAction !== 'addendum' ? purchaseData.filesData : []} filePermissions={filePermission} />
          <Row>
            <Col span={24}>
              <Form layout='vertical' form={remarksFormRef} initialValues={{ remarks: purchaseData.remarks }} >
                <Form.Item label="Remarks" name="remarks">
                  <TextArea style={{ width: '100%' }} rows={5} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Row justify="end">
            <Col>
              <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={fileUploadFormHandler} />
              {/* {
                <Space>
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    overlay={menu}
                  >
                    {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                  </Dropdown.Button>
                  <Button onClick={fileUploadFormHandler} type="primary">
                    Save and Next
                  </Button>
                </Space>
              } */}

            </Col>
          </Row>
        </Panel>
        <Panel header="Preview" key="8">
          <>
            <ContractDetailsView addendumData={purchaseData.addendumData} contractDetails={purchaseData.contractDetails} shippingTerms={purchaseData.shippingTerms} contractMode={ContractModesEnum.SALES} plantCode={authContext.defaultPlant} snapShotData={snapData} contractType={props?.soAction === 'addendum' ? ContractTypesEnum.ADDENDUM : ContractTypesEnum.ORIGINAL} />
            <br></br>
            <Card title={'Quality Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
              <QualityDetailView classes='contracts' qualityData={purchaseData?.qualityDetails?.qualityData} type={ContractModesEnum.SALES} plantCode={authContext.defaultPlant} snapShotData={snapData} contractType={props?.soAction === 'addendum' ? ContractTypesEnum.ADDENDUM : ContractTypesEnum.ORIGINAL} vat={vat} />
              {/* <QualityPreview data={purchaseData.qualityDetails.qualityData} purchaseTypeData={purchaseTypeData} purchaseTermData={purchaseTermData} /> */}
            </Card>
            <br></br>


            <SurveyorDetailsView surveyorDetails={purchaseData?.surveyorDetails} snapShotData={snapData} contractType={props?.soAction === 'addendum' ? ContractTypesEnum.ADDENDUM : ContractTypesEnum.ORIGINAL} />

            <PaymentTermsView paymentTerms={purchaseData?.paymentTerms} snapShotData={snapData} contractType={props?.soAction === 'addendum' ? ContractTypesEnum.ADDENDUM : ContractTypesEnum.ORIGINAL} />

            <ShippingTermsView shippingTerms={purchaseData?.shippingTerms} snapShotData={snapData} contractType={props?.soAction === 'addendum' ? ContractTypesEnum.ADDENDUM : ContractTypesEnum.ORIGINAL} />

            <CommercialDocumentsView commercialDocuments={purchaseData?.commercialDocuments} snapShotData={snapData} contractType={props?.soAction === 'addendum' ? ContractTypesEnum.ADDENDUM : ContractTypesEnum.ORIGINAL} />
            <Row justify="end">
              <Col>
                <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={savePurchaseContract} />
                {/* {
                  <Space>
                    <Dropdown.Button
                      icon={<DownOutlined />}
                      overlay={menu}
                    >
                      {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                    </Dropdown.Button>
                    <Button onClick={savePurchaseContract} type="primary">
                      Submit
                    </Button>
                  </Space>
                } */}
              </Col>
            </Row>

          </>
        </Panel>
      </Collapse>
    </div >
  )
}

export default SalesContract;