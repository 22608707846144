import { ContractIdRequest, ContractModesEnum, ContractStatusEnum, ContractTypesEnum, GetTaxesByBpIdDto, ItemsCodesEnum, ItemsIdRequestDto, PurchaseContractDto, SalesContractService, TaxesAndItemsService } from '@exportx/shared-models-and-services';
import { getFilesData } from '@exportx/ui-utils';
import { Affix, Button, Card, Col, Form, Layout, Row, Space } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import { AlertMessages, FileUploadForm, isPermissionExist, StatusTag, useAuthState } from '../../../common';
import CommercialDocumentsView from './commercial-documents';
import ContractDetailsView from './contract-details-view';
import MarketingFeesForSales from './marketing-fees';
import PaymentTermsView from './payment-terms-view';
import AssociatedBusinessNumbers from './pc-associated-business-numbers';
import QualityDetailView from './quality-detail-view';
import ShippingTermsView from './shipping-terms-view';
import SurveyorDetailsView from './surveyor-details-view';


const assertString = (str) => {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  } else {
    return false;
  }
}

const isBase64 = (str) => {
  const notBase64 = /[^A-Z0-9+\/=]/i;
  if (assertString(str)) {
    const len = str.length;
    if (!len || len % 4 !== 0 || notBase64.test(str)) {
      return false;
    }
    const firstPaddingChar = str.indexOf('=');
    return firstPaddingChar === -1 ||
      firstPaddingChar === len - 1 ||
      (firstPaddingChar === len - 2 && str[len - 1] === '=');
  } else {
    return false;
  }

}


export const SalesContractDetailView = () => {
  const { authContext } = useAuthState();
  const { formatMessage: fm } = useIntl();
  const [filesFormRef] = Form.useForm();
  const printRef = React.useRef();
  const navigate = useNavigate();
  const [previewData, setPreviewData] = useState<PurchaseContractDto>(null);
  const [snapData, setSnapData] = useState<PurchaseContractDto[]>([]);
  const [contractDetails, setContractDetails] = useState(null);
  const [shippingTerms, setShippingTerms] = useState(null);
  const [vat, setVat] = useState<GetTaxesByBpIdDto[]>([]);
  const location = useLocation();
  const paramScId = queryString.parse(location.search)?.sc_id;
  const paramPlantCode = queryString.parse(location.search)?.plantCode;

  let { contractId }: any = paramScId != null ? { contractId: paramScId } : { contractId: null };
  let { plantCode }: any = paramPlantCode != null ? { plantCode: paramPlantCode } : { plantCode: authContext.defaultPlant };


  const service = new SalesContractService();
  const taxesAndItemsService = new TaxesAndItemsService();

  useEffect(() => {
    if (!isPermissionExist([96])) return navigate('/')
  }, [])

  useEffect(() => {
    getSalesPreviewData(contractId, plantCode);
  }, [contractId]);

  const getSalesPreviewData = (contractId: string, plantCode: string) => {
    const req = new ContractIdRequest(contractId, plantCode);
    service.getSalesPreviewData(req).then(res => {
      if (res.status) {
        setPreviewData(res.data);
        setSnapData(res?.snapData ? res?.snapData : []);
        setContractDetails(res.data.contractDetails);
        if (res.data.contractDetails.contractDate) {
          getTaxesByItemsId(res.data.contractDetails.contractDate);
        }
        setShippingTerms(res.data.shippingTerms);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }

  const getTaxesByItemsId = (contractDate) => {
    const req = new ItemsIdRequestDto(ItemsCodesEnum.COAL, contractDate)
    taxesAndItemsService.getVatByItemsId(req).then(res => {
      if (res.status) {
        setVat(res.data);
      } else {
        setVat([]);
      }
    }).catch(err => {
      console.log(err);
      setVat([]);
    });
  }


  const handleDownloadPdf = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('print.pdf');
  };

  const sendForApproval = () => {
    const req = { ...new ContractIdRequest(contractId), plantCode };
    service.sendForApproval(req).then(res => {
      if (res.status) {
        getSalesPreviewData(contractId, plantCode);
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Sale Order Sent successfully' }));
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }



  // const addendumApproval = () => {
  //   const req = { ...new ContractIdRequest(contractId), plantCode };
  //   service.approveSalesAddendum(req).then(res => {
  //     if (res.status) {
  //       getSalesPreviewData(contractId, plantCode);
  //       AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Sales Order Addendum Sent successfully' }));
  //     } else {
  //       AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
  //     }
  //   }).catch(err => {
  //     AlertMessages.getErrorMessage(err.message);
  //   })
  // }

  const saleForReject = () => {
    const req = new ContractIdRequest(contractId, authContext.defaultPlant)
    service.saleForReject(req).then(res => {
      if (res.status) {
        getSalesPreviewData(contractId, plantCode);
        AlertMessages.getSuccessMessage(res.internalMessage);
      } else {
        AlertMessages.getErrorMessage(res.error);
      }

    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }
  const salesForApproval = () => {
    const req = { ...new ContractIdRequest(contractId), plantCode, userName: authContext.user.userName };
    if (previewData?.contractType === ContractTypesEnum.ADDENDUM) {
      service.approveSalesAddendum(req).then(res => {
        if (res.status) {
          getSalesPreviewData(contractId, plantCode);
          AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Sales Order Addendum Sent successfully' }));
        } else {
          AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));

        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })

    } else {
      service.approveSaleOrder(req).then(res => {
        if (res.status) {
          getSalesPreviewData(contractId, plantCode);
          AlertMessages.getSuccessMessage(res.internalMessage);
        } else {
          AlertMessages.getErrorMessage(res.error);
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message);
      })
    }


  }

  const addendumClickHandler = () => {
    navigate('/so-addendum', { state: previewData });
  }
  const getExtraComponent = (status: any) => {
    if (status === ContractStatusEnum.DRAFT) {
      return <><Button onClick={() => sendForApproval()}>Send For Approval</Button></>
    }


    if (status === ContractStatusEnum.PENDING_APPROVAL) {
      return <> {isPermissionExist([104]) && <Button size="small" onClick={() => salesForApproval()}>Approve</Button>};

        {isPermissionExist([105]) && <Button size="small" onClick={() => saleForReject()}>Reject</Button>}</>;
    }
    // if (status === ContractStatusEnum.PENDING_APPROVAL) {
    //   return <><Button hidden={previewData?.contractType === 'Addendum' ? false : true} onClick={() => addendumApproval()}>Approve</Button></>
    // }
    if (status === ContractStatusEnum.ACTIVE && isPermissionExist([101])) {
      return <><Button hidden={previewData?.contractType === 'Addendum' ? true : false} onClick={() => addendumClickHandler()}>Create Addendum</Button></>
    }
  }

  const setFormData = () => {
    navigate('/so-update', { state: previewData });
  }

  const fileUploadFormHandler = () => {
    filesFormRef.validateFields().then(fileValues => {
      getFilesData(fileValues.filesData).then(filesData => {
        setPreviewData((prevState) => { return { ...prevState, filesData: [...prevState.filesData, ...filesData] } });
        service.addMoreFilesToSaleOrder({
          filesData: filesData,
          updatedUser: authContext.user.userName,
          contractId: contractId
        }).then(res => {
          if (res.status) {
            getSalesPreviewData(contractId, plantCode);
            filesFormRef.resetFields();
            AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: 'Sale Order Sent successfully' }));
          } else {
            AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
          }
        }).catch(err => {
          AlertMessages.getErrorMessage(err.message);
        })
      });
    });
  }

  const scQuality = previewData?.qualityDetails.qualityData.reduce((a, c) => a + Number(c.quality), 0)

  console.log(previewData?.contractType, "77777777777777777")
  return (
    <>
      <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}>
        <Row justify="end">
          <Col><> <Button href="/#/so-tracker" style={{ color: 'black' }}>SO Tracker</Button> <Button style={{ marginLeft: 20 }} onClick={handleDownloadPdf}>Download Sale Order</Button>
          </></Col></Row>
        <Card
          title={<span >SO Detail View - {contractDetails?.['contractNo']}
            &nbsp;- <StatusTag status={contractDetails?.['status']} />
          </span>
          }
          style={{ textAlign: 'center' }}
          headStyle={{ backgroundColor: '#2196f3', color: '#fff' }}
          ref={printRef}
          extra={<>{getExtraComponent(contractDetails?.['status'])}</>}>
          <br></br>
          <ContractDetailsView addendumData={previewData?.addendumData} contractDetails={contractDetails} shippingTerms={shippingTerms} contractMode={ContractModesEnum.SALES} plantCode={plantCode} snapShotData={snapData} contractType={previewData?.contractType} />
          <br></br>
          <Card title={'Quality Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px', overflow: 'auto' }}>
            <QualityDetailView classes='contracts' qualityData={previewData?.qualityDetails?.qualityData} type={ContractModesEnum.SALES} plantCode={plantCode} snapShotData={snapData} contractType={previewData?.contractType} vat={vat} />
          </Card>
          <br></br>
          <SurveyorDetailsView surveyorDetails={previewData?.surveyorDetails} snapShotData={snapData} contractType={previewData?.contractType} />

          <PaymentTermsView paymentTerms={previewData?.paymentTerms} snapShotData={snapData} contractType={previewData?.contractType} />

          <ShippingTermsView shippingTerms={previewData?.shippingTerms} snapShotData={snapData} contractType={previewData?.contractType} />

          <CommercialDocumentsView commercialDocuments={previewData?.commercialDocuments} snapShotData={snapData} contractType={previewData?.contractType} />
          <Row>
            {previewData?.remarks && <Col xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}>
              <Card title={<span style={{ color: 'black' }}>{'Remarks'}</span>} style={{ textAlign: 'left' }} headStyle={{ backgroundColor: '#e6e0e0', border: 0 }}>
                <pre>
                  {previewData.remarks}
                </pre>
              </Card>
            </Col>}
          </Row>
          <MarketingFeesForSales contractId={previewData?.contractDetails?.pcId} contractType={previewData?.contractType} />

          <AssociatedBusinessNumbers contractId={contractId} pcQuantityTotal={scQuality} contractType={ContractModesEnum.SALES} />


          {previewData?.filesData && <FileUploadForm maxCount={3} layoutType='vertical' formRef={filesFormRef} initialValues={previewData?.filesData} contractType={previewData?.contractType}>
            <Row justify="end">
              <Col>
                {
                  <Space>
                    <Button onClick={fileUploadFormHandler} type="primary">
                      Save and Next
                    </Button>
                  </Space>
                }</Col>
            </Row>
          </FileUploadForm>}

        </Card><br></br>
        <Affix offsetBottom={0}>
          <Card bodyStyle={{ padding: '6px 12px' }}>
            <Row justify='space-between'>
              <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
              <Col>{isPermissionExist([97]) && (contractDetails?.['status'] === ContractStatusEnum.DRAFT || contractDetails?.['status'] === ContractStatusEnum.PENDING_APPROVAL) && <Button onClick={setFormData}>Edit</Button>}</Col>
            </Row>
          </Card>
        </Affix>
      </Layout>
    </>
  )
}
export default SalesContractDetailView;